<template>
  <div class="column-center">
    <span class="org-page-english" style="margin-top: 50px">Organisation</span>
    <span class="org-page-title"> 组织机构 </span>
    <img :src="icons.intro_triangle2" height="15" style="margin-top: 10px; margin-bottom: 55px" />

    <!-- <div class="full-width column-center org-center-container" style="height: 1000px">
      <div class="row" style="width: 1200px">
        <a-space direction="vertical" style="width: 300px">
          <template v-for="item in orgs">
            <div class="row label-title" style="align-items: flex-start" v-if="currentOrgsId == item.id" :key="item.id">
              <img :src="icons.org_image2" width="10" style="margin-top: 8px" />
              <div class="column" style="margin-left: 35px">
                <span class="label-selected">{{ item.title }}</span>
                <img :src="icons.org_image1" width="66" style="margin-top: 30px" />
              </div>
            </div>
            <div @click="toggleTab(item)" v-else :key="item.id" class="label-title">
              <span class="label-default">{{ item.title }}</span>
            </div>
          </template>
        </a-space>
        <div class="org-container">
          <div class="column warpper">
            <div class="row" style="align-items: flex-start; margin-bottom: 45px" v-for="(item, index) in orgsChildren" :key="index">
              <img :src="item.img_url" width="260" height="210" />
              <div class="column" style="margin-left: 25px">
                <span class="lable-content-title">{{ item.name }}</span>
                <span class="lable-content-desc">{{ item.content }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div>
      <div class="column org-content-width" v-for="item in orgs" :key="item.id">
        <span class="org-page-english" style="margin-top: 50px; margin-bottom: 50px">{{ item.title }}</span>
        <!-- <div class="row-wrapper">
          <div v-for="(orgItem,index) in item.children" :key="index" class="column-center" style="width:260px;margin-left:15px;margin-right:15px;margin-bottom:30px">
            <img :src="orgItem.img_url" width="260" height="210" />
            <span style="font-size:18px;font-weight:bold;margin-top:10px;min-height:60px">{{ orgItem.name }}</span>
            <span style="font-size:14px;margin-top:20px;">{{ orgItem.content }}</span>
          </div>
        </div> -->
        <div class="row" style="align-items: flex-start; margin-bottom: 45px" v-for="(orgItem, index) in item.children"
          :key="index">
          <img :src="orgItem.img_url" width="260" height="210" />
          <div class="column" style="margin-left: 25px; flex: 1">
            <span class="lable-content-title">{{ orgItem.name }}</span>
            <span class="lable-content-desc">{{ orgItem.content }}</span>
          </div>
        </div>
      </div>
    </div>

    <span class="org-page-english" style="margin-top: 50px">Médias coopératifs</span>
    <span class="org-page-title"> 媒体支持 </span>
    <img :src="icons.intro_triangle2" height="15" style="margin-top: 10px; margin-bottom: 55px" />

    <img :src="adImg" class="row-wrapper org-content-width" />

    <!-- <div class="row-wrapper org-content-width">
      <img class="btn" @click="goNewPage(item)" v-for="(item, index) in mediaList" :key="index" :src="item.img_url" width="260px" height="70px" style="margin-right:40px;margin-top:40px;" />
    </div> -->

    <!-- <div class="row org-content-width" style="margin-top: 150px">
      <img class="btn" style="flex: 1" @click="goNewPage(mediaStaticList[0])" :src="mediaStaticList[0].img_url" width="165px" height="50px" />
      <div style="
          width: 1px;
          height: 35px;
          margin: 0 90px;
          background-color: #656565;
        "></div>
      <img class="btn" style="flex: 1" @click="goNewPage(mediaStaticList[1])" :src="mediaStaticList[1].img_url" width="165px" height="50px" />
      <div style="
          width: 1px;
          height: 35px;
          margin: 0 90px;
          background-color: #656565;
        "></div>
      <img class="btn" style="flex: 1" @click="goNewPage(mediaStaticList[2])" :src="mediaStaticList[2].img_url" width="165px" height="50px" />
      <div style="
          width: 1px;
          height: 35px;
          margin: 0 90px;
          background-color: #656565;
        "></div>
      <img class="btn" style="flex: 1" @click="goNewPage(mediaStaticList[3])" :src="mediaStaticList[3].img_url" width="165px" height="50px" />
    </div> -->

    <div style="height: 130px"></div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as eventHelper from "../../utils/eventHelper";
export default {
  data() {
    return {
      icons: this.$root.icons,

      currentOrgsId: 1,
      orgsChildren: [],
      mediaList: [],
      mediaStaticList: [{}, {}, {}, {}],

      adImg: null,

      orgs: [
        {
          id: 1,
          title: "发起&主办机构",
          children: [],
        },
        {
          id: 2,
          title: "联合主办单位",
          children: [],
        },
        {
          id: 3,
          title: "承办单位",
          children: [],
        },
        {
          id: 6,
          title: "协办单位",
          children: [],
        },
        {
          id: 9,
          title: "“思维导图单元”联合承办单位",
          children: [],
        },
        {
          id: 11,
          title: "“星星的你单元”联合主办单位",
          children: [],
        },
        {
          id: 10,
          title: "特别战略合作单位",
          children: [],
        },
        {
          id: 4,
          title: "特别赞助",
          children: [],
        },
        {
          id: 5,
          title: "场地支持",
          children: [],
        },
        {
          id: 7,
          title: "法律顾问单位",
          children: [],
        },
        {
          id: 8,
          title: "支持单位",
          children: [],
        },
      ],
    };
  },
  mounted() {
    this.getOrgDetail(this.currentOrgsId);
    this.getMediaList();
    this.getMediaStaticList();
    this.getImg();

    for (let item of this.orgs) {
      this.orgGetListAction({
        cate_id: item.id,
      })
        .then((res) => {
          item.children = res;
        })
        .catch((error) => {
          console.error(error);
          this.$message.error(error);
        });
    }

    setTimeout(() => {
      eventHelper.triggerEvent("footer-change-background", {
        backgroundColor: "#062C3E",
        fontColor: "#ffffff",
      });
    }, 500);
  },
  methods: {
    ...mapActions({
      orgGetListAction: "orgGetList",
      mediaGetListAction: "mediaGetList",
      positionGetContentAction: "positionGetContent",
    }),
    toggleTab(item) {
      this.currentOrgsId = item.id;
      this.getOrgDetail(this.currentOrgsId);
    },
    goNewPage(item) {
      if (item.href) {
        window.open(item.href);
      }
    },
    getOrgDetail(id) {
      this.orgGetListAction({
        cate_id: id,
      })
        .then((res) => {
          this.orgsChildren = res;
        })
        .catch((error) => {
          console.error(error);
          this.$message.error(error);
        });
    },
    getMediaList() {
      this.mediaGetListAction({
        type: 1,
      })
        .then((res) => {
          this.mediaList = res;
        })
        .catch((error) => {
          console.error(error);
          this.$message.error(error);
        });
    },
    getMediaStaticList() {
      this.mediaGetListAction({
        type: 2,
      })
        .then((res) => {
          this.mediaStaticList = res;
        })
        .catch((error) => {
          console.error(error);
          this.$message.error(error);
        });
    },
    getImg() {
      this.positionGetContentAction({
        position_id: 3,
      }).then((res) => {
        this.adImg = res.img_url;
      });
    },
  },
};
</script>

<style scoped>
.org-content-width {
  width: 800px;
}
.org-page-english {
  font-family: PingFangSC-Semibold;
  font-size: 26px;
  font-weight: bold;
  color: #282828;
  letter-spacing: 0;
  text-align: center;
}
.org-page-title {
  font-family: PingFangSC-Semibold;
  font-size: 36px;
  color: #282828;
  letter-spacing: 0;
  text-align: center;
}

.org-center-container {
  background-image: linear-gradient(
    -46deg,
    #062c3e 0%,
    #073246 44%,
    #062c3e 100%
  );
  padding-top: 160px;
}

.org-container {
  overflow-x: scroll;
  height: 700px;
  flex: 1;
}

.label-title {
  cursor: pointer;
  margin-bottom: 55px;
}

.label-default {
  font-family: ZhenyanGB-Regular;
  font-size: 24px;
  color: #ffffff;
  letter-spacing: 0;
}

.label-selected {
  font-family: ZhenyanGB-Regular;
  font-size: 36px;
  color: #ffffff;
  letter-spacing: 0;
}

.label-subtitle {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0;
}

.lable-content-title {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #282828;
  letter-spacing: 0;
}

.lable-content-desc {
  margin-top: 45px;
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #282828;
  letter-spacing: 0;
  white-space: pre-line;
}

.org-partner {
  font-family: PingFangSC-Regular;
  font-size: 32px;
  color: #656565;
  letter-spacing: 0;
}

.org-partner-small {
  font-family: PingFangSC-Regular;
  font-size: 24px;
  color: #656565;
  letter-spacing: 0;
}
</style>